import styled from "styled-components";

export const SkillsSectionContainer = styled.div`
  width: 100%;
  margin: 0 auto 0 auto;
  padding: 4rem 0;
  background-color: #f5f5f5;
  scroll-margin-top: 80px;

  @media (max-width: 1024px) {
    padding: 2rem 0;
  }
`;

export const SkillsSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  width: 90%;

  @media (max-width: 1024px) {
    max-width: 800px;
  }

  @media (max-width: 768px) {
    max-width: 600px;
  }

  @media (max-width: 480px) {
    max-width: 400px;
  }
`;

export const SkillsTitle = styled.h1`
  font-size: 2rem;
  margin: 0 0 10px 0;
  font-family: "EB Garamond", serif;
  font-weight: 600;
  text-align: center;
  border-bottom: 2px solid #ff0000;
  padding-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
`;

export const SkillsSectionSubTitle = styled.p`
  font-size: 1.2rem;
  margin: 0;
  font-family: "EB Garamond", serif;
  font-weight: normal;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const SkillsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 1rem;
  margin-top: 2rem;
  width: 100%;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const SkillBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #f5f5f5;
  border-radius: 10px;
  transition: background-color 0.3s ease;
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    background-color: #e0e0e0;
  }
`;

export const SkillIcon = styled.div`
  width: 50px;
  height: 50px;
  margin-bottom: 1rem;
  text-align: center;
`;

export const SkillTitle = styled.h3`
  font-size: 1rem;
  margin: 0;
  font-family: "EB Garamond", serif;
  font-weight: 600;
  color: #333333;
  text-align: center;
`;
