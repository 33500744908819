import styled from "styled-components";

export const BioSectionContainer = styled.div`
  width: 100%;
  margin: 0 auto 0 auto;
  scroll-margin-top: 80px;
  padding: 4rem 0;

  @media (max-width: 1024px) {
    padding: 2rem 0;
  }
`;

export const BioSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  width: 90%;

  @media (max-width: 1024px) {
    max-width: 800px;
  }

  @media (max-width: 768px) {
    max-width: 600px;
  }
`;

export const BioTitle = styled.h1`
  font-size: 2rem;
  margin: 0 0 10px 0;
  font-family: "EB Garamond", serif;
  font-weight: 600;
  text-align: center;
  border-bottom: 2px solid #ff0000;
  padding-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
`;

export const BioSubTitle = styled.p`
  font-size: 1.2rem;
  margin: 0;
  font-family: "EB Garamond", serif;
  font-weight: normal;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const BioContent = styled.p`
  font-size: 1.3rem;
  width: 70%;
  text-align: center;
  font-family: "EB Garamond", serif;
  font-weight: normal;
  color: #333333;

  @media (max-width: 1024px) {
    width: 80%;
  }

  @media (max-width: 768px) {
    width: 90%;
    font-size: 1.1rem;
  }
`;
