import {
  ProjectContent,
  ProjectSectionContainer,
  ProjectSectionWrapper,
  ProjectSubTitle,
  ProjectTitle,
} from "./projects.styled";

export function HomePageProjectsSection({ id }: { id: string }) {
  return (
    <>
      <ProjectSectionContainer id={id}>
        <ProjectSectionWrapper>
          <ProjectTitle>Projects & Research Experience</ProjectTitle>
          <ProjectSubTitle>
            Some of My Research and Development Activities
          </ProjectSubTitle>
          <ProjectContent>
            Journal Paper: Hosseinzadeh, S., Mollajan, A., Shams, R.,
            Iranmanesh, S.H., 2024, "Mathematical Model for Balancing Water
            Conservation and Electricity Generation with Floating Solar in
            Water-Scarce Environments" (peer review).
          </ProjectContent>
          <ProjectContent style={{ marginTop: 0 }}>
            Conference: Shams, R., Alimohammadzadeh, R., 2022, "Studying and
            Comparing Different Consensus Methods of Blockchain", The First
            National Conference on Innovative Research in Electrical and
            Computer Engineering.
          </ProjectContent>
          <ProjectContent style={{ marginTop: 0 }}>
            Collaborated within an enterprise team in development of deep
            learning models and computer vision applications to create a 3D full
            body avatar based on 2D selfie image of the user.
          </ProjectContent>
          <ProjectContent style={{ marginTop: 0 }}>
            Developed a new high-quality simulator to measure decentralized
            network performance by modifying an existing low-quality one based
            on related articles with Python for the M.Sc. thesis
          </ProjectContent>
          <ProjectContent style={{ marginTop: 0 }}>
            Translated complex mathematical models into software algorithms
            using GAMS, collaborated with associate writers, and prepared
            graphical representations of processed results for a journal paper.
          </ProjectContent>
          <ProjectContent style={{ marginTop: 0 }}>
            Collaborated within an academic team to develop an AI tool designed
            to detect the main object in an image and remove its background
            using PyMatting, Torch, TorchVision, and Scikit-Image.
          </ProjectContent>
          <ProjectContent style={{ marginTop: 0 }}>
            Engineered IoT-based hardware inclusing custom PCB board, and
            crafting programs with C programming language for ESP32 modules to
            precisely control four servo motors, managing their angles and
            statuses for the B.Sc. project.
          </ProjectContent>
          <ProjectContent style={{ marginTop: 0 }}>
            Contributed to the research and development of off-chain and
            on-chain blockchain networks utilizing Python, Solidity, and
            JavaScript to empower trustless and serverless DAO service.
          </ProjectContent>
          <ProjectContent style={{ marginTop: 0 }}>
            Designed and implemented a mobile application using Swift
            programming language to interface with controller and manipulate an
            arm robot, integrating the device's 3-axis gyroscope for the B.Sc.
            project.
          </ProjectContent>
        </ProjectSectionWrapper>
      </ProjectSectionContainer>
    </>
  );
}
