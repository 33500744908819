import {
  BlogBox,
  BlogCoverImage,
  BlogDate,
  BlogGrid,
  BlogSectionContainer,
  BlogSectionSubTitle,
  BlogSectionTitle,
  BlogSectionWrapper,
  BlogSummary,
  BlogTitle,
  NoPostsBox,
  NoPostsIcon,
  NoPostsTitle,
  ViewAllBlogButton,
} from "./blog.styled";
import ReactTimeAgo from "react-time-ago";
import { useGetBlogPosts } from "../../api/blog";
import { Link } from "react-router-dom";
import { TbMoodEmpty } from "react-icons/tb";

export function HomePageBlogSection({ id }: { id: string }) {
  const { posts, postsEmpty } = useGetBlogPosts({ limit: 3 });

  return (
    <>
      <BlogSectionContainer id={id}>
        <BlogSectionWrapper>
          <BlogSectionTitle>Blog</BlogSectionTitle>
          <BlogSectionSubTitle>New Arrivals of The Blog</BlogSectionSubTitle>

          {postsEmpty && (
            <NoPostsBox>
              <NoPostsIcon>
                <TbMoodEmpty />
              </NoPostsIcon>

              <NoPostsTitle>No Posts Yet!</NoPostsTitle>
            </NoPostsBox>
          )}

          <BlogGrid>
            {(posts || []).map((post) => (
              <BlogBox key={post.uuid}>
                <BlogCoverImage src={post.coverImage} alt={post.title} />
                <BlogTitle>{post.title}</BlogTitle>
                <BlogDate>
                  <ReactTimeAgo
                    date={new Date(post.createdAt)}
                    locale="en-US"
                  />
                </BlogDate>
                <BlogSummary>{post.summary}</BlogSummary>
              </BlogBox>
            ))}
          </BlogGrid>

          <Link to="/blog">
            <ViewAllBlogButton>View All Posts</ViewAllBlogButton>
          </Link>
        </BlogSectionWrapper>
      </BlogSectionContainer>
    </>
  );
}
