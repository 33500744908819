import { TopMenu } from "../../components/menu";
import { Footer } from "../../components/footer";
import useScrollToHash from "../../hooks/useScrollToHash";
import { BlogPageAllPosts } from "../../sections/blog/all";

export function BlogPage() {
  useScrollToHash();

  return (
    <>
      <TopMenu />

      <BlogPageAllPosts />

      <Footer />
    </>
  );
}
