import styled from "styled-components";

export const FooterBox = styled.div`
  background-color: #333;
  padding: 20px;
  box-sizing: border-box;
  margin: 0;
  width: 100%;
`;

export const FooterWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
`;

export const FooterCopyright = styled.div`
  color: #fff;
  font-size: 1rem;

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

export const FooterSocialMediaIcons = styled.div`
  display: flex;
  gap: 10px;

  a {
    color: #fff;
    font-size: 20px;

    &:hover {
      color: #cecece;
    }
  }
`;
