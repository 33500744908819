import React, { useState, useEffect } from "react";
import useWindowScroll from "../../hooks/useWindowScroll";
import {
  ContactButton,
  MenuItem,
  MenuWrapper,
  TopMenuContainer,
  TopMenuLogo,
  TopMenuWrapper,
} from "./top.styled";
import { Link } from "react-router-dom";

export const TopMenu = () => {
  const [scroll, setScroll] = useState(false);
  const { y: scrollY } = useWindowScroll();

  useEffect(() => {
    if (scrollY > 0) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  }, [scrollY]);

  return (
    <TopMenuContainer scroll={scroll}>
      <TopMenuWrapper>
        <Link to="/">
          <TopMenuLogo>Reza Shams</TopMenuLogo>
        </Link>
        <MenuWrapper>
          <MenuItem to="/#bio">Bio</MenuItem>
          <MenuItem to="/#education">Education</MenuItem>
          <MenuItem to="/#career">Career</MenuItem>
          <MenuItem to="/#projects">Projects</MenuItem>
          <MenuItem to="/#certificates">Certificates</MenuItem>
          <MenuItem to="/#skills">Skills</MenuItem>
          <MenuItem to="/blog">Blog</MenuItem>
        </MenuWrapper>
        <Link to="/#contact">
          <ContactButton>Contact Me</ContactButton>
        </Link>
      </TopMenuWrapper>
    </TopMenuContainer>
  );
};
