'use client';

import { useRef } from 'react';
import { closeSnackbar, SnackbarProvider as NotistackProvider } from 'notistack';

import IconButton from '@mui/material/IconButton';

import { StyledIcon, StyledNotistack } from './styles';
import { FaCheckCircle, FaInfoCircle, FaTimesCircle } from 'react-icons/fa';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function SnackbarProvider({ children }: Props) {
  const notistackRef = useRef<any>(null);

  return (
    <NotistackProvider
      ref={notistackRef}
      maxSnack={5}
      preventDuplicate
      autoHideDuration={3000}
      TransitionComponent={undefined}
      variant="success" // Set default variant
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      iconVariant={{
        info: (
          <StyledIcon color="info">
            <FaInfoCircle />
          </StyledIcon>
        ),
        success: (
          <StyledIcon color="success">
            <FaCheckCircle />
          </StyledIcon>
        ),
        warning: (
          <StyledIcon color="warning">
            <FaInfoCircle />
          </StyledIcon>
        ),
        error: (
          <StyledIcon color="error">
            <FaInfoCircle />
          </StyledIcon>
        ),
      }}
      Components={{
        default: StyledNotistack,
        info: StyledNotistack,
        success: StyledNotistack,
        warning: StyledNotistack,
        error: StyledNotistack,
      }}
      // with close as default
      action={(snackbarId) => (
        <IconButton size="small" onClick={() => closeSnackbar(snackbarId)} sx={{ p: 0.5 }}>
          <FaTimesCircle />
        </IconButton>
      )}
    >
      {children}
    </NotistackProvider>
  );
}
