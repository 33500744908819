import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import {
  CertificateDescription,
  CertificateSubTitle,
  CertificateTitle,
  CertificatesGrid,
  CertificatesSectionContainer,
  CertificatesSectionSubTitle,
  CertificatesSectionWrapper,
  CertificatesTitle,
} from "./certificates.styled";
import { MdExpandMore } from "react-icons/md";
import { certificates } from "../../__mock/certificates";

export function HomePageCertificatesSection({ id }: { id: string }) {
  return (
    <>
      <CertificatesSectionContainer id={id}>
        <CertificatesSectionWrapper>
          <CertificatesTitle>Certificates</CertificatesTitle>
          <CertificatesSectionSubTitle>I'm Honored To Achieve These</CertificatesSectionSubTitle>

          <CertificatesGrid>
            {certificates.map((certificate) => (
              <Accordion key={certificate.id}>
                <AccordionSummary
                  expandIcon={<MdExpandMore />}
                  aria-controls={`certificate${certificate.id}-content`}
                  id={`certificate${certificate.id}-header`}
                >
                  <CertificateTitle>
                    {certificate.title} - {certificate.organization}
                  </CertificateTitle>
                </AccordionSummary>
                <AccordionDetails>
                  <CertificateDescription>
                    {certificate.description}
                  </CertificateDescription>

                  {certificate.objectives.map((x) => (
                    <CertificateDescription>{x}</CertificateDescription>
                  ))}

                  <CertificateSubTitle>
                    Issue Date: {certificate.year} - Verification Code:{" "}
                    {certificate.code} on {certificate.issuer}
                  </CertificateSubTitle>
                </AccordionDetails>
              </Accordion>
            ))}
          </CertificatesGrid>
        </CertificatesSectionWrapper>
      </CertificatesSectionContainer>
    </>
  );
}
