import styled from "styled-components";

export const ContactSectionContainer = styled.div`
  width: 100%;
  margin: 0 auto 0 auto;
  padding: 0 0 4rem 0;
  scroll-margin-top: 80px;

  @media (max-width: 768px) {
    padding: 0 0 2rem 0;
  }
`;

export const ContactSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  width: 90%;

  & form {
    width: 100%;
  }

  @media (max-width: 768px) {
    max-width: 90%;
  }
`;

export const ContactTitle = styled.h1`
  font-size: 2rem;
  margin: 0 0 10px 0;
  font-family: "EB Garamond", serif;
  font-weight: 600;
  text-align: center;
  border-bottom: 2px solid #ff0000;
  padding-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
`;

export const ContactSubTitle = styled.p`
  font-size: 1.2rem;
  margin: 0 0 2rem 0;
  font-family: "EB Garamond", serif;
  font-weight: normal;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const SubmitButton = styled.button<{ isLoading: boolean }>`
  background-color: ${(props) => (props.isLoading ? "#ccc" : "#000000")};
  color: #fff;
  padding: 10px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: ${(props) => (props.isLoading ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.isLoading ? 0.7 : 1)};
  transition: all 0.3s ease-in-out;
  float: right;

  &:hover {
    background-color: #ff0000;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 8px 16px;
  }
`;

export const ContactSocialGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    width: 100%;
  }
`;

export const ContactSocialGridBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2rem;
  background-color: #f5f5f5;
  border-radius: 10px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e0e0e0;
  }
`;

export const ContactSocialGridIcon = styled.div`
  margin-bottom: 1rem;
`;

export const ContactSocialGridTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 10px;
  font-family: "EB Garamond", serif;
  font-weight: 600;
`;

export const ContactSocialGridSubTitle = styled.p`
  font-size: 1rem;
  font-family: "EB Garamond", serif;
  font-weight: normal;
  margin: 0;
`;