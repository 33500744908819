import { Link } from "react-router-dom";
import styled from "styled-components";

interface TopMenuContainerProps {
  scroll: boolean;
}

export const TopMenuContainer = styled.div<TopMenuContainerProps>`
  background-color: ${({ scroll }) => (scroll ? "#FFFFFF" : "transparent")};
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 100;
  transition: background-color 0.3s ease;

  @media (max-width: 768px) {
    /* Styles for tablets and smaller */
    height: 80px;
  }

  @media (max-width: 480px) {
    /* Styles for mobiles */
    height: 80px;
  }
`;

export const TopMenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  max-width: 1200px;
  margin: 0 auto;
  width: 90%;

  @media (max-width: 768px) {
    /* Styles for tablets and smaller */
    height: 80px;
    padding: 0 20px;
  }

  @media (max-width: 480px) {
    /* Styles for mobiles */
    height: 80px;
    padding: 0 10px;
  }
`;

export const TopMenuLogo = styled.span`
  font-size: 1.5rem;
  font-weight: 700;
  font-family: "EB Garamond", serif;

  @media (max-width: 480px) {
    /* Styles for mobiles */
    font-size: 1.2rem;
  }
`;

export const MenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 970px) {
    /* Styles for tablets and smaller */
    display: none;
  }
`;

export const MenuItem = styled(Link)`
  font-size: 16px;
  font-weight: 500;
  margin: 0 20px;
  cursor: pointer;

  &:hover {
    color: #ff0000;
  }

  @media (max-width: 480px) {
    /* Styles for mobiles */
    font-size: 14px;
    margin: 0 10px;
  }
`;

export const ContactButton = styled.button`
  background-color: #000000;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: none;

  &:hover {
    background-color: #ff0000;
  }

  @media (max-width: 480px) {
    /* Styles for mobiles */
    font-size: 1rem;
    padding: 8px 16px;
  }
`;
