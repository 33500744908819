import React, { type ReactElement } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes as Switch,
} from "react-router-dom";
import { PAGE_ROUTES } from "./page-routes";
import NotFound from "../sections/errors/not-found";

const Routes: React.FC = (): ReactElement => {
  return (
    <Router>
      <Switch>
        {PAGE_ROUTES.map(({ id, path, element }) => (
          <Route key={id} path={path} element={element} />
        ))}

        <Route path="*" element={<NotFound />} />
      </Switch>
    </Router>
  );
};

export default Routes;
