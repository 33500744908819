import {
  IntroContentContainer,
  IntroImageContainer,
  IntroScrollIcon,
  IntroScrollMoreContainer,
  IntroScrollText,
  IntroSectionContainer,
  IntroSectionWrapper,
  IntroSubTitle,
  IntroTitle,
} from "./intro.styled";
import { MdMouse } from "react-icons/md";

export function HomePageIntroSection() {
  return (
    <>
      <IntroSectionContainer>
        <IntroSectionWrapper>
          <IntroContentContainer>
            <IntroTitle>
              Hi, I'm <strong>Reza Shams</strong>
            </IntroTitle>
            <IntroSubTitle>
              A software engineer, Full-Stack developer, Currently a
              tech-lead, AI enthusiast, Passionate to learn, research, And an
              adventurer
            </IntroSubTitle>
          </IntroContentContainer>
          <IntroImageContainer />
        </IntroSectionWrapper>

        <IntroScrollMoreContainer>
          <IntroScrollIcon>
            <MdMouse />
          </IntroScrollIcon>
          <IntroScrollText>Scroll To See More</IntroScrollText>
        </IntroScrollMoreContainer>
      </IntroSectionContainer>
    </>
  );
}
