import { TbMoodEmpty } from "react-icons/tb";
import { useGetBlogPosts } from "../../api/blog";
import useScrollToHash from "../../hooks/useScrollToHash";
import {
  BlogBox,
  BlogCoverImage,
  BlogDate,
  BlogGrid,
  BlogSummary,
  NoPostsBox,
  NoPostsIcon,
  NoPostsTitle,
  BlogTitle as BlogBoxTitle
} from "../home/blog.styled";
import {
  BlogSectionContainer,
  BlogSectionWrapper,
  BlogSubTitle,
  BlogTitle,
} from "./all.styled";
import ReactTimeAgo from "react-time-ago";

export function BlogPageAllPosts() {
  useScrollToHash();

  const { posts, postsEmpty } = useGetBlogPosts({});

  return (
    <>
      <BlogSectionContainer>
        <BlogSectionWrapper>
          <BlogTitle>Blog</BlogTitle>
          <BlogSubTitle>What I Write in Various Fields</BlogSubTitle>

          {postsEmpty && (
            <NoPostsBox>
              <NoPostsIcon>
                <TbMoodEmpty />
              </NoPostsIcon>

              <NoPostsTitle>No Posts Yet!</NoPostsTitle>
            </NoPostsBox>
          )}

          <BlogGrid>
            {(posts || []).map((post) => (
              <BlogBox key={post.uuid}>
                <BlogCoverImage src={post.coverImage} alt={post.title} />
                <BlogBoxTitle>{post.title}</BlogBoxTitle>
                <BlogDate>
                  <ReactTimeAgo
                    date={new Date(post.createdAt)}
                    locale="en-US"
                  />
                </BlogDate>
                <BlogSummary>{post.summary}</BlogSummary>
              </BlogBox>
            ))}
          </BlogGrid>
        </BlogSectionWrapper>
      </BlogSectionContainer>
    </>
  );
}
