import useSWR from 'swr';
import { useMemo } from 'react';

import { fetcher, endpoints } from '../utils/axios';
import { BlogPost } from '../types/collection';

// ----------------------------------------------------------------------

export function useGetBlogPosts(params: { q?: string, skip?: number, limit?: number }) {
  const { q = '', skip = 0, limit = 20 } = params
  const URL = [endpoints.blog.all, { params: { q, skip, limit } }];

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher, {
    keepPreviousData: true,
  });

  const memoizedValue = useMemo(
    () => ({
      posts: (data?.data.posts as BlogPost[]) || [],
      postsTotalCount: (data?.data.totalCount as number) || 0,
      postsLoading: isLoading,
      postsError: error,
      postsValidating: isValidating,
      postsEmpty: !isLoading && !data?.data.posts.length,
    }),
    [data?.data.posts, data?.data.totalCount, error, isLoading, isValidating]
  );

  return memoizedValue;
}

// ----------------------------------------------------------------------

export function useGetBlogPost(id: string) {
  const URL = id ? endpoints.blog.details(id) : '';

  const { data, isLoading, error, isValidating } = useSWR(URL, fetcher);

  const memoizedValue = useMemo(
    () => ({
      post: data?.data.post as BlogPost,
      postLoading: isLoading,
      postError: error,
      postValidating: isValidating,
    }),
    [data?.data.post, error, isLoading, isValidating]
  );

  return memoizedValue;
}
