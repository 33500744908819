import React from "react";
import { TopMenu } from "../../components/menu";
import {
  NotFoundButton,
  NotFoundContainer,
  NotFoundContent,
} from "./not-found.styled";
import { BiError } from "react-icons/bi";
import { Link } from "react-router-dom";

const NotFound: React.FC = () => {
  return (
    <>
      <TopMenu />

      <NotFoundContainer>
        <NotFoundContent>
          <BiError size={100} />
          <h1>404 Not Found</h1>
          <p>Oops! The page you're looking for does not exist.</p>
          <Link to="/">
            <NotFoundButton>Go Back to Home</NotFoundButton>
          </Link>
        </NotFoundContent>
      </NotFoundContainer>
    </>
  );
};

export default NotFound;
