import {
  BioContent,
  BioSectionContainer,
  BioSectionWrapper,
  BioSubTitle,
  BioTitle,
} from "./bio.styled";

export function HomePageBioSection({ id }: { id: string }) {
  return (
    <>
      <BioSectionContainer id={id}>
        <BioSectionWrapper>
          <BioTitle>Bio</BioTitle>
          <BioSubTitle>A Glance Look At My Background</BioSubTitle>
          <BioContent>
            An enthusiastic software engineer with over 8 years of experience in
            architecting enterprise software and developing applications using
            various programming languages, including Python, JavaScript,
            TypeScript, and PHP. Demonstrated strong collaborative and
            management skills by effectively leading multiple software
            development teams. Passionate about researching on programming
            languages, software engineering, machine learning, software
            security enhancements, and blockchain.
          </BioContent>
        </BioSectionWrapper>
      </BioSectionContainer>
    </>
  );
}
