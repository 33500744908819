interface Certificate {
  id: string;
  title: string;
  issuer: string;
  organization: string;
  code: string;
  url: string;
  icon: string;
  description: string;
  objectives: string[];
  year: number;
}

export const certificates: Certificate[] = [
  {
    id: "1",
    title: "Improving Deep Neural Networks",
    issuer: "Coursera",
    organization: "DeepLearnin.AI",
    code: "3M3697CH3HNL",
    url: "https://coursera.com/3M3697CH3HNL",
    icon: "coursera-logo.png",
    description: "Objectives and covered topics:",
    objectives: [
      "A variety of different initialization methods are discovered and experimented with, L2 regularization and dropout are applied to avoid model overfitting, and gradient checking is then applied to identify errors in a fraud detection model.",
      "The deep learning toolbox is developed by incorporating more advanced optimizations, implementing random minibatching, and incorporating learning rate decay scheduling to accelerate the models.",
      "TensorFlow, a deep learning framework that enables the building of neural networks quickly and easily, is explored, followed by the training of a neural network on a TensorFlow dataset."
    ],
    year: 2024,
  },
  {
    id: "2",
    title: "Sequence Models",
    issuer: "Coursera",
    organization: "DeepLearnin.AI",
    code: "4EFRZ7B3WT26",
    url: "https://coursera.com/4EFRZ7B3WT26",
    icon: "coursera-logo.png",
    description: "Objectives and covered topics:",
    objectives: [
      "Recurrent neural networks, a type of model that performs extremely well on temporal data, along with several of its variants, including LSTMs, GRUs, and Bidirectional RNNs, are discovered.",
      "The powerful combination of natural language processing with deep learning is acknowledged. Recurrent neural networks are trained with outstanding performance across a wide variety of applications, including sentiment analysis, named entity recognition, and neural machine translation, using word vector representations and embedding layers.",
      "Sequence models can be augmented using an attention mechanism, an algorithm that assists in determining where to focus attention given a sequence of inputs. Subsequently, speech recognition and methods for handling audio data are explored.",
    ],
    year: 2024,
  },
  {
    id: "3",
    title: "Convolutional Neural Networks",
    issuer: "Coursera",
    organization: "DeepLearnin.AI",
    code: "2JZN5BNQZE22",
    url: "https://coursera.com/2JZN5BNQZE22",
    icon: "coursera-logo.png",
    description: "Objectives and covered topics:",
    objectives: [
      "The foundational layers of CNNs (pooling, convolutions) are implemented and properly stacked in a deep network to solve multi-class image classification problems.",
      "Some powerful practical tricks and methods used in deep CNNs are discovered straight from the research papers, and transfer learning is then applied to your own deep CNN.",
      "Your new knowledge of CNNs is applied to one of the hottest (and most challenging!) fields in computer vision: object detection.",
      "The exploration of how CNNs can be applied to multiple fields, including art generation and face recognition, is undertaken, followed by the implementation of your own algorithm to generate art and recognize faces!",
    ],
    year: 2024,
  },
  {
    id: "4",
    title: "Structuring Machine Learning projects",
    issuer: "Coursera",
    organization: "DeepLearnin.AI",
    code: "Y2CK2X23LDW2",
    url: "https://coursera.com/Y2CK2X23LDW2",
    icon: "coursera-logo.png",
    description: "Objectives and covered topics:",
    objectives: [
      "The ML production workflow is streamlined and optimized by implementing strategic guidelines for goal-setting and applying human-level performance to help define key priorities.",
      "Time-saving error analysis procedures are developed to evaluate the most worthwhile options to pursue and gain intuition for how to split the data and when to use multi-task, transfer, and end-to-end deep learning.",
    ],
    year: 2024,
  },
  {
    id: "5",
    title: "Neural Networks and Deep Learning",
    issuer: "Coursera",
    organization: "DeepLearnin.AI",
    code: "3XC58RV9R9FK",
    url: "https://coursera.com/3XC58RV9R9FK",
    icon: "coursera-logo.png",
    description: "Objectives and covered topics:",
    objectives: [
      "The major trends driving the rise of deep learning are analyzed, and examples of where and how it is applied today are given.",
      "A machine learning problem is set up with a neural network mindset, and vectorization is used to speed up the models.",
      "A neural network with one hidden layer is built, utilizing forward propagation and backpropagation.",
      "The key computations underlying deep learning are analyzed, and they are then used to build and train deep neural networks for computer vision tasks.",
    ],
    year: 2023,
  },
  {
    id: "6",
    title: "Advanced React",
    issuer: "Coursera",
    organization: "Meta",
    code: "FNC6FZT6TYKE",
    url: "https://coursera.com/FNC6FZT6TYKE",
    icon: "coursera-logo.png",
    description: "Objectives and covered topics:",
    objectives: [
      "Robust and reusable components are created with advanced techniques, and different patterns are learned to reuse common behavior.",
      "React applications are seamlessly tested with React Testing Library.",
      "A remote server is interacted with, and data is fetched and posted via an API.",
      "Commonly used React libraries are integrated to streamline application development.",
    ],
    year: 2023,
  },
  {
    id: "7",
    title: "Developing Back-End Apps with Node.js and Express",
    issuer: "Coursera",
    organization: "IBM",
    code: "8VXZD8HVND5M",
    url: "https://coursera.com/8VXZD8HVND5M",
    icon: "coursera-logo.png",
    description: "Objectives and covered topics:",
    objectives: [
      "Server-side applications are created using the Node.js JavaScript runtime.",
      "Node.js applications are extended with third-party packages and frameworks, including Express.",
      "Node.js packages are managed in your Node.js application using npm.",
      "Asynchronous callback functions and promises are utilized to complete asynchronous operations."
    ],
    year: 2023,
  },
];
