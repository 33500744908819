import {
  EducationBox,
  EducationBoxSubTitle1,
  EducationBoxSubTitle2,
  EducationBoxSubTitle3,
  EducationBoxTitle,
  EducationBoxesContainer,
  EducationIcon,
  EducationSectionContainer,
  EducationSectionSubTitle,
  EducationSectionWrapper,
  EducationTitle,
} from "./education.styled";

export function HomePageEducationSection({ id }: { id: string }) {
  return (
    <>
      <EducationSectionContainer id={id}>
        <EducationSectionWrapper>
          <EducationTitle>Education</EducationTitle>
          <EducationSectionSubTitle>
            My Academic Accomplishments
          </EducationSectionSubTitle>

          <EducationBoxesContainer>
            <EducationBox>
              <EducationIcon>
                <img
                  src="tabrizu-logo.png"
                  alt="Tabriz University - Reza Shams"
                />
              </EducationIcon>
              <EducationBoxTitle>B.Sc.</EducationBoxTitle>
              <EducationBoxSubTitle1>
                Mechanical Engineering
              </EducationBoxSubTitle1>
              <EducationBoxSubTitle2>
                University of Tabriz
              </EducationBoxSubTitle2>
              <EducationBoxSubTitle3>
                Project Title: Building a 3-Joint Mechanical Arm by Designing a
                Custom PCB Board and Dedicated IoT-Based Mobile App to Control
                by Gyroscope.
              </EducationBoxSubTitle3>
            </EducationBox>

            <EducationBox>
              <EducationIcon>
                <img
                  src="azadu-logo.png"
                  alt="Azad University of Tehran - Reza Shams"
                />
              </EducationIcon>
              <EducationBoxTitle>M.Sc.</EducationBoxTitle>
              <EducationBoxSubTitle1>
                Computer Engineering - Software
              </EducationBoxSubTitle1>
              <EducationBoxSubTitle2>
                Azad University of Tehran - North Branch
              </EducationBoxSubTitle2>
              <EducationBoxSubTitle3>
                Thesis Title: A New Method for Blockchain Networks Consensus
                Algorithms Performance Evaluation.
              </EducationBoxSubTitle3>
            </EducationBox>
          </EducationBoxesContainer>
        </EducationSectionWrapper>
      </EducationSectionContainer>
    </>
  );
}
