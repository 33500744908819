import React, { ReactElement } from "react";
import { ROUTES } from "./routes-path";
import { BlogPage, HomePage } from "../pages";

export const PAGE_ROUTES: {
  id: number;
  path: string;
  element: ReactElement;
}[] = [
  {
    id: 1,
    path: ROUTES.INDEX,
    element: <HomePage />,
  },
  {
    id: 2,
    path: ROUTES.BLOG,
    element: <BlogPage />,
  },
];
