interface CareerItem {
  order: number;
  key: string;
  date: string;
  title: string;
  company: string;
  bulletPoints: string[];
}

export const Career: CareerItem[] = [
  {
    order: 1,
    key: "1",
    date: "Oct 2023 - Present",
    title: "Technical Team Lead",
    company: "BADNAVA - Doha, Qatar",
    bulletPoints: [
      "Developed AI models by combining deep learning neural networks and image processing to facilitate virtual try-on of products resulting in enhanced user experience and business growth.",
      "Designed an SQL database architecture to optimize data delivery to the client side, employing Nest.js for scalability of services.",
      "Orchestrated seamless continuous delivery of updates to staging and production servers using GitLab Pipelines in conjunction with AWS servers.",
      "Translated business requirements into comprehensive technical documents, guiding the development of tailored tech stacks that propelled the startup to the top 1% in Dubai DIFC.",
      "Spearheaded a technical team, instilling best practices through mentorship, regular meetings, and knowledge-sharing sessions to drive team cohesion and efficiency.",
    ],
  },
  {
    order: 2,
    key: "2",
    date: "Sep 2022 - Oct 2023",
    title: "Senior Software Engineer",
    company: "Ernyka Group - Tehran, Iran",
    bulletPoints: [
      "Strengthened the code base to achieve better integrity, maintainability, and scalability by using TypeScript for both the front-end and back-end side of the applications.",
      "Improved the overall application speed by using 40+ Queues and Tasks in Node.js for heavy and asynchronous processes and reducing redundant processes.",
      "Streamlined infrastructure management processes by developing an AI assistant using Python-based machine learning libraries, leading to the authorization of business innovations in the Netherlands.",
      "Utilized DevOps practices to streamline 100% automated deployment and maintainability.",
      "Mentored 5 team members by providing best practices, code reviews, knowledge sharing, and daily Scrum meetings.",
    ],
  },
  {
    order: 3,
    key: "3",
    date: "Sep 2022 - Oct 2023",
    title: "Software Engineer",
    company: "ParsJahd - Tehran, Iran",
    bulletPoints: [
      "Designed a comprehensive image dataset leveraging company resources, culminating in the development of full-body 3D avatar generation based on avatar photos using Python AI tools.",
      "Expanded the scalability of the software by using a decentralized off-chain database on multiple nodes powered by GraphQL, as well as developing smart contracts to maintain DAO services.",
      "Improved code base reliability by implementing test plans using Jest with 80+ unit & integration tests.",
      "Enhanced the integration of the 6 dev teams by developing a comprehensive JavaScript SDK.",
      "Revitalized a struggling team of 4 with outcome-focused coaching and product improvements.",
    ],
  },
  {
    order: 4,
    key: "4",
    date: "Feb 2019 - Sep 2020",
    title: "Full-Stack Web Developer",
    company: "Memargram - Tehran, Iran",
    bulletPoints: [
      "Optimized the performance of MySQL database to perform 3x faster using comprehensive indexing and redundant process reduction resulting in user satisfaction improvement.",
      "Expanded accessibility to 50k+ users of the service by developing the PWA application.",
      "Refactored existing website using Next.js to perform faster and deliver a seamless user experience."
    ],
  },
  {
    order: 5,
    key: "5",
    date: "Sep 2016 - Feb 2019",
    title: "Full-Stack Web Developer",
    company: "Masterfoodeh - Tehran, Iran",
    bulletPoints: [
      "Reduced manual efforts by developing CRM, ERP, and BOM software solutions using Laravel for the company, resulting in the growth of R&D product development by 150%.",
      "Gained experience in translating 10+ business requirements into production-ready applications.",
    ],
  },
  {
    order: 6,
    key: "6",
    date: "Jun 2013 - Aug 2016",
    title: "Full-Stack Web & Mobile Developer and Co-Founder",
    company: "Binarleap - Tehran, Iran",
    bulletPoints: [
      "Handled mass data (5+ million records) processing using optimized PHP and SQL processes and indexes.",
      "Gained professional experience in customer relationship management and translating client needs into business logic.",
      "Successfully delivered various software projects to individual or enterprise clients.",
    ],
  },
];
