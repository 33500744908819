import * as _ from 'lodash';
import axios, { AxiosRequestConfig } from 'axios';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_URL });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  blog: {
    all: '/api/common/v1/blog',
    details: (sku: string) => `/api/common/v1/blog/${sku}`,
  },
  contactUs: {
    create: '/api/common/v1/contact',
  },
};

export const handleErrorResponse = (
  error: any,
  setError: any,
  enqueueSnackbar?: any,
  forSnackbar?: string[]
) => {
  if (error.statusCode === 400) {
    error.errors.map((err: any) => {
      if (forSnackbar && enqueueSnackbar) {
        if (forSnackbar.includes(err.property)) {
          enqueueSnackbar(err.constraints.join(', '), { variant: 'error' });
        } else {
          setError(err.property, { message: err.constraints.join(', ') });
        }
      } else {
        setError(err.property, { message: err.constraints.join(', ') });
      }

      return true;
    });
  } else if (enqueueSnackbar) {
    enqueueSnackbar(_.get(error, 'message', 'Unknown Error!'), { variant: 'error' });
  }
};

export const handleErrorResponseAsSnack = (
  error: any,
  enqueueSnackbar: any,
) => {
  if (error.statusCode === 400) {
    error.errors.map((err: any) => {
      enqueueSnackbar(err.constraints.join(', '), { variant: 'error' });

      return true;
    });
  } else {
    enqueueSnackbar(_.get(error, 'message', 'Unknown Error!'), { variant: 'error' });
  }
};
