import styled from "styled-components";

export const CareerSectionContainer = styled.div`
  width: 100%;
  margin: 0 auto 0 auto;
  padding: 4rem 0;
  background-color: #f5f5f5;
  scroll-margin-top: 80px;

  @media (max-width: 1024px) {
    padding: 2rem 0;
  }
`;

export const CareerSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  width: 90%;

  @media (max-width: 1024px) {
    max-width: 800px;
  }

  @media (max-width: 768px) {
    max-width: 600px;
  }

  @media (max-width: 480px) {
    max-width: 400px;
  }
`;

export const CareerSectionSubTitle = styled.p`
  font-size: 1.2rem;
  margin: 0;
  font-family: "EB Garamond", serif;
  font-weight: normal;
  text-align: center;

  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;

export const CareerTitle = styled.h1`
  font-size: 2rem;
  margin: 0 0 10px 0;
  font-family: "EB Garamond", serif;
  font-weight: 600;
  text-align: center;
  border-bottom: 2px solid #ff0000;
  padding-bottom: 10px;

  @media (max-width: 480px) {
    font-size: 1.8rem;
  }
`;

export const CareerTimelineContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 2rem;
  width: 100%;

  @media (max-width: 480px) {
    margin-top: 1rem;
  }
`;

export const CareerTimelineItem = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: 2px;
    height: 100%;
    background-color: #ff0000;
    position: absolute;
    left: 14px;
    top: 5px;
    z-index: 1;
  }

  &:last-child:before {
    display: none;
  }

  @media (max-width: 480px) {
    &:before {
      display: none;
    }
  }
`;

export const CareerTimelineCircle = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ff0000;
  margin-right: 1rem;
  border: 5px solid #f5f5f5;
  z-index: 2;

  @media (max-width: 480px) {
    display: none;
  }
`;

export const CareerTimelineContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CareerTimelineDate = styled.p`
  font-size: 1rem;
  margin: 0;
  font-family: "EB Garamond", serif;
  font-weight: normal;
  color: #666666;

  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;

export const CareerTimelineJobTitle = styled.h3`
  font-size: 1.5rem;
  margin: 0;
  font-family: "EB Garamond", serif;
  font-weight: 600;
  color: #333333;

  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

export const CareerTimelineCompany = styled.h4`
  font-size: 1rem;
  margin: 0;
  font-family: "EB Garamond", serif;
  font-weight: normal;
  color: #666666;

  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;

export const CareerBulletPoints = styled.ul`
  list-style-type: disc;
  margin-top: 1rem;
  padding-left: 2rem;

  @media (max-width: 480px) {
    padding-left: 1rem;
  }
`;

export const CareerBulletPoint = styled.li`
  font-size: 1rem;
  margin-bottom: 0.5rem;
  font-family: "EB Garamond", serif;
  font-weight: normal;
  color: #333333;

  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;
