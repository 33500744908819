import { TopMenu } from "../../components/menu";
import { Footer } from "../../components/footer";
import { HomePageContactUsSection } from "../../sections/home/contact";
import { HomePageBlogSection } from "../../sections/home/blog";
import { HomePageSkillsSection } from "../../sections/home/skills";
import { HomePageCertificatesSection } from "../../sections/home/certificates";
import { HomePageCareerSection } from "../../sections/home/career";
import { HomePageEducationSection } from "../../sections/home/education";
import { HomePageBioSection } from "../../sections/home/bio";
import { HomePageIntroSection } from "../../sections/home/intro";
import useScrollToHash from "../../hooks/useScrollToHash";
import { HomePageProjectsSection } from "../../sections/home/projects";

export function HomePage() {
  useScrollToHash()
  
  return (
    <>
      <TopMenu />

      <HomePageIntroSection />

      <HomePageBioSection id="bio" />

      <HomePageEducationSection id="education" />

      <HomePageCareerSection id="career" />

      <HomePageProjectsSection id="projects" />

      <HomePageCertificatesSection id="certificates" />

      <HomePageSkillsSection id="skills" />

      <HomePageBlogSection id="blog" />

      <HomePageContactUsSection id="contact" />

      <Footer />
    </>
  );
}
