import {
  CareerBulletPoint,
  CareerBulletPoints,
  CareerSectionContainer,
  CareerSectionSubTitle,
  CareerSectionWrapper,
  CareerTimelineCircle,
  CareerTimelineCompany,
  CareerTimelineContainer,
  CareerTimelineContent,
  CareerTimelineDate,
  CareerTimelineItem,
  CareerTimelineJobTitle,
  CareerTitle,
} from "./career.styled";
import { Career } from "../../__mock/career";

export function HomePageCareerSection({ id }: { id: string }) {
  return (
    <>
      <CareerSectionContainer id={id}>
        <CareerSectionWrapper>
          <CareerTitle>Professional Experience</CareerTitle>
          <CareerSectionSubTitle>My Footsteps In Some Companies</CareerSectionSubTitle>

          <CareerTimelineContainer>
            {Career.sort((a, b) => a.order - b.order).map((career) => (
              <CareerTimelineItem>
                <CareerTimelineCircle />

                <CareerTimelineContent>
                  <CareerTimelineDate>{career.date}</CareerTimelineDate>
                  <CareerTimelineJobTitle>
                    {career.title}
                  </CareerTimelineJobTitle>
                  <CareerTimelineCompany>
                    {career.company}
                  </CareerTimelineCompany>
                  <CareerBulletPoints>
                    {career.bulletPoints.map((x) => (
                      <CareerBulletPoint>{x}</CareerBulletPoint>
                    ))}
                  </CareerBulletPoints>
                </CareerTimelineContent>
              </CareerTimelineItem>
            ))}
          </CareerTimelineContainer>
        </CareerSectionWrapper>
      </CareerSectionContainer>
    </>
  );
}
