import styled, { keyframes } from "styled-components";

export const IntroSectionContainer = styled.div`
  width: 100%;
  margin: 80px auto 0 auto;
  background-color: #f5f5f5;
  padding: 4rem 0;

  @media (max-width: 1024px) {
    padding: 2rem 0;
  }
`;

export const IntroSectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const IntroImageContainer = styled.div`
  flex: 1;
  background-image: url("top-image.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 500px;

  @media (max-width: 1024px) {
    min-height: 300px;
    margin-bottom: 2rem
  }
`;

export const IntroContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 2rem;

  @media (max-width: 1024px) {
    padding: 2rem;
  }
`;

export const IntroTitle = styled.h1`
  font-size: 3rem;
  margin: 0 0 10px 0;
  font-family: "EB Garamond", serif;
  font-weight: 600;

  > strong {
    color: #ff0000;
  }

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

export const IntroSubTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: normal;
  margin: 0;
  font-family: "EB Garamond", serif;
  color: #333333;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const IntroBlinkAnimation = keyframes`
    0% {
        opacity: 0;
        transform: translateY(0);
    }
    50% {
        opacity: 1;
        transform: translateY(-10px);
    }
    100% {
        opacity: 0;
        transform: translateY(0);
    }
`;

export const IntroScrollMoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const IntroScrollIcon = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  animation: ${IntroBlinkAnimation} 2s infinite;
  text-align: center;

  @media (max-width: 768px) {
    width: 20px;
    height: 20px;
  }
`;

export const IntroScrollText = styled.p`
  font-size: 1rem;
  margin-top: 0;
  color: #333333;

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;
