import styled from "styled-components";

export const CertificatesSectionContainer = styled.div`
  width: 100%;
  margin: 0 auto 0 auto;
  padding: 0 0 4rem 0;
  scroll-margin-top: 80px;
`;

export const CertificatesSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  width: 90%;

  @media screen and (max-width: 768px) {
    max-width: 90%;
  }
`;

export const CertificatesTitle = styled.h1`
  font-size: 2rem;
  margin: 0 0 10px 0;
  font-family: "EB Garamond", serif;
  font-weight: 600;
  text-align: center;
  border-bottom: 2px solid #ff0000;
  padding-bottom: 10px;

  @media screen and (max-width: 768px) {
    font-size: 1.8rem;
  }
`;

export const CertificatesSectionSubTitle = styled.p`
  font-size: 1.2rem;
  margin: 0;
  font-family: "EB Garamond", serif;
  font-weight: normal;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const CertificatesGrid = styled.div`
  margin-top: 2rem;
  width: 100%;

  @media screen and (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  @media screen and (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

export const CertificateTitle = styled.h3`
  font-size: 1.5rem;
  margin: 0;
  font-family: "EB Garamond", serif;
  font-weight: 600;
  color: #333333;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

export const CertificateSubTitle = styled.h4`
  font-size: 1rem;
  margin: 0;
  font-family: "EB Garamond", serif;
  font-weight: normal;
  color: #666666;

  @media screen and (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

export const CertificateDescription = styled.p`
  font-size: 1.2rem;
  margin: 0 0 1rem 0;
  font-family: "EB Garamond", serif;
  font-weight: normal;
  white-space: pre-line;

  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const CertificateBadges = styled.ul`
  list-style-type: none;
  margin-top: 1rem;
  padding: 0;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const CertificateBadge = styled.li`
  display: inline-block;
  font-size: 0.8rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.3rem 0.6rem;
  background-color: #ff0000;
  color: #ffffff;
  border-radius: 5px;
  font-family: "EB Garamond", serif;
  font-weight: normal;

  @media screen and (max-width: 768px) {
    font-size: 0.7rem;
  }
`;