import {
  SkillBox,
  SkillIcon,
  SkillTitle,
  SkillsGrid,
  SkillsSectionContainer,
  SkillsSectionSubTitle,
  SkillsSectionWrapper,
  SkillsTitle,
} from "./skills.styled";
import { skills } from "../../__mock/skills";

export function HomePageSkillsSection({ id }: { id: string }) {
  return (
    <>
      <SkillsSectionContainer id={id}>
        <SkillsSectionWrapper>
          <SkillsTitle>Skills</SkillsTitle>
          <SkillsSectionSubTitle>What I'm Most Experienced At</SkillsSectionSubTitle>
          <SkillsGrid>
            {skills.map((skill) => {
              return (
                <SkillBox>
                  <SkillIcon>{skill.icon}</SkillIcon>
                  <SkillTitle>{skill.title}</SkillTitle>
                </SkillBox>
              );
            })}
          </SkillsGrid>
        </SkillsSectionWrapper>
      </SkillsSectionContainer>
    </>
  );
}
