import styled from "styled-components";

export const BlogSectionContainer = styled.div`
  width: 100%;
  margin: 0 auto 0 auto;
  padding: 4rem 0;
  scroll-margin-top: 80px;
`;

export const BlogSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  width: 90%;
`;

export const BlogSectionTitle = styled.h1`
  font-size: 2rem;
  margin: 0 0 10px 0;
  font-family: "EB Garamond", serif;
  font-weight: 600;
  text-align: center;
  border-bottom: 2px solid #ff0000;
  padding-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }
`;

export const BlogSectionSubTitle = styled.p`
  font-size: 1.2rem;
  margin: 0;
  font-family: "EB Garamond", serif;
  font-weight: normal;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const BlogGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
  margin-top: 2rem;
  width: 100%;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const BlogBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #f5f5f5;
  border-radius: 10px;
  transition: background-color 0.3s ease;
  box-sizing: border-box;
  cursor: pointer;

  &:hover {
    background-color: #e0e0e0;
  }

  @media (max-width: 768px) {
    padding: 1rem;;
  }
`;

export const BlogCoverImage = styled.img`
  width: 100%;
  display: block;
  border-radius: 10px;
`;

export const BlogTitle = styled.h3`
  font-size: 1.5rem;
  margin: 1rem 0 0.5rem 0;
  font-family: "EB Garamond", serif;
  font-weight: 600;
  color: #333333;
  text-align: center;
`;

export const BlogDate = styled.p`
  font-size: 1rem;
  margin: 0;
  font-family: "EB Garamond", serif;
  font-weight: normal;
  color: #666666;
  text-align: center;
`;

export const BlogSummary = styled.p`
  font-size: 1.2rem;
  margin: 1rem 1rem 0 1rem;
  font-family: "EB Garamond", serif;
  font-weight: normal;
  color: #333333;
  text-align: center;
`;

export const ViewAllBlogButton = styled.button`
  font-size: 1rem;
  margin: 2rem 0 0 auto;
  background-color: transparent;
  color: #000000;
  border: none;
  font-family: "EB Garamond", serif;
  font-weight: normal;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: color 0.3s ease;

  &:hover {
    color: #ff0000;
  }
`;

export const NoPostsBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  margin-top: 3rem;
`;

export const NoPostsIcon = styled.span`
  font-size: 3rem;
`;

export const NoPostsTitle = styled.h3`
  font-size: 1.5rem;
  margin: 0;
  font-family: "EB Garamond", serif;
  font-weight: 600;
  color: #333333;
  text-align: center;
`;