import { useEffect, useState } from 'react';

const useWindowScroll = (): { x: number; y: number } => {
    const [scrollPosition, setScrollPosition] = useState({ x: 0, y: 0 });

    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition({ x: window.scrollX, y: window.scrollY });
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return scrollPosition;
};

export default useWindowScroll;