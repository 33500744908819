import {
  FooterBox,
  FooterCopyright,
  FooterSocialMediaIcons,
  FooterWrapper,
} from "./styled";
import { SiInstagram, SiLinkedin } from "react-icons/si";

export const Footer = () => {
  return (
    <FooterBox>
      <FooterWrapper>
        <FooterCopyright>
          © {new Date().getFullYear()} Reza Shams. All rights reserved.
        </FooterCopyright>
        <FooterSocialMediaIcons>
          <a href="https://instagram.com/reza.shams.1" target="_blank" rel="noreferrer">
            <SiInstagram />
          </a>

          <a href="https://linkedin.com/in/rezashams1" target="_blank" rel="noreferrer">
            <SiLinkedin />
          </a>
        </FooterSocialMediaIcons>
      </FooterWrapper>
    </FooterBox>
  );
};
