import styled from "styled-components";

export const NotFoundContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 90%;
  margin: 0 auto;
  font-size: 24px;
  text-align: center;

  @media (max-width: 768px) { /* Small laptops and tablets */
    flex-direction: column;
  }

  @media (max-width: 480px) { /* Mobiles */
    font-size: 18px;
  }
`;

export const NotFoundContent = styled.div``;

export const NotFoundButton = styled.button`
  background-color: #000000;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: none;

  &:hover {
    background-color: #ff0000;
  }

  @media (max-width: 480px) { /* Mobiles */
    font-size: 14px;
    padding: 8px 16px;
  }
`;
