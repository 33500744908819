import {
  SiTypescript,
  SiJavascript,
  SiPhp,
  SiPython,
  SiSolidity,
  SiMysql,
  SiPostgresql,
  SiMongodb,
  SiEthereum,
  SiTrello,
  SiJira,
  SiGit,
  SiGithub,
  SiGitlab,
  SiAzuredevops,
  SiDocker,
  SiKubernetes,
  SiPostman,
  SiGraphql,
  SiReact,
  SiVercel,
  SiNodedotjs,
  SiExpress,
  SiNestjs,
  SiRedux,
  SiLaravel,
  SiNumpy,
  SiScikitlearn,
  SiPandas,
  SiTensorflow,
  SiPytorch,
} from "react-icons/si";

interface Skill {
  id: string;
  icon: any;
  title: string;
  category: string;
}

export const skills: Skill[] = [
  {
    id: "1",
    icon: <SiPython size={40} />,
    title: "Python",
    category: "Programming Languages",
  },
  {
    id: "2",
    icon: <SiJavascript size={40} />,
    title: "JavaScript",
    category: "Programming Languages",
  },
  {
    id: "3",
    icon: <SiTypescript size={40} />,
    title: "TypeScript",
    category: "Programming Languages",
  },
  {
    id: "4",
    icon: <SiPhp size={40} />,
    title: "PHP",
    category: "Programming Languages",
  },
  {
    id: "5",
    icon: <SiSolidity size={40} />,
    title: "Solidity",
    category: "Programming Languages",
  },
  {
    id: "6",
    icon: <SiMysql size={40} />,
    title: "MySQL",
    category: "Database",
  },
  {
    id: "7",
    icon: <SiPostgresql size={40} />,
    title: "PostgreSQL",
    category: "Database",
  },
  {
    id: "8",
    icon: <SiMongodb size={40} />,
    title: "MongoDB",
    category: "Database",
  },
  {
    id: "9",
    icon: <SiEthereum size={40} />,
    title: "Ceramic",
    category: "Database",
  },
  {
    id: "10",
    icon: <SiTrello size={40} />,
    title: "Trello",
    category: "Project Management",
  },
  {
    id: "11",
    icon: <SiJira size={40} />,
    title: "Jira",
    category: "Project Management",
  },
  {
    id: "12",
    icon: <SiGit size={40} />,
    title: "Git",
    category: "Version Control",
  },
  {
    id: "13",
    icon: <SiGithub size={40} />,
    title: "Github",
    category: "Version Control",
  },
  {
    id: "14",
    icon: <SiGitlab size={40} />,
    title: "Gitlab",
    category: "Version Control",
  },
  {
    id: "15",
    icon: <SiAzuredevops size={40} />,
    title: "CI/CD",
    category: "DevOps",
  },
  {
    id: "16",
    icon: <SiDocker size={40} />,
    title: "Docker",
    category: "DevOps",
  },
  {
    id: "17",
    icon: <SiKubernetes size={40} />,
    title: "Kubernetes",
    category: "DevOps",
  },
  {
    id: "18",
    icon: <SiPostman size={40} />,
    title: "REST",
    category: "API",
  },
  {
    id: "19",
    icon: <SiGraphql size={40} />,
    title: "GraphQL",
    category: "API",
  },
  {
    id: "20",
    icon: <SiReact size={40} />,
    title: "React.js",
    category: "Libraries & Frameworks",
  },
  {
    id: "21",
    icon: <SiVercel size={40} />,
    title: "Next.js",
    category: "Libraries & Frameworks",
  },
  {
    id: "22",
    icon: <SiNodedotjs size={40} />,
    title: "Node.js",
    category: "Libraries & Frameworks",
  },
  {
    id: "23",
    icon: <SiExpress size={40} />,
    title: "Express.js",
    category: "Libraries & Frameworks",
  },
  {
    id: "24",
    icon: <SiNestjs size={40} />,
    title: "Nest.js",
    category: "Libraries & Frameworks",
  },
  {
    id: "25",
    icon: <SiRedux size={40} />,
    title: "Redux",
    category: "Libraries & Frameworks",
  },
  {
    id: "26",
    icon: <SiLaravel size={40} />,
    title: "Laravel",
    category: "Libraries & Frameworks",
  },
  {
    id: "27",
    icon: <SiNumpy size={40} />,
    title: "NumPy",
    category: "Libraries & Frameworks",
  },
  {
    id: "28",
    icon: <SiScikitlearn size={40} />,
    title: "SciKit",
    category: "Libraries & Frameworks",
  },
  {
    id: "29",
    icon: <SiPandas size={40} />,
    title: "Pandas",
    category: "Libraries & Frameworks",
  },
  {
    id: "30",
    icon: <SiTensorflow size={40} />,
    title: "TensorFlow",
    category: "Libraries & Frameworks",
  },
  {
    id: "31",
    icon: <SiPytorch size={40} />,
    title: "PyTorch",
    category: "Libraries & Frameworks",
  },
  {
    id: "32",
    icon: <SiPython size={40} />,
    title: "PyMatting",
    category: "Libraries & Frameworks",
  },
];
