import { Grid } from "@mui/material";
import FormProvider from "../../components/hook-form/form-provider";
import {
  ContactSectionContainer,
  ContactSectionWrapper,
  ContactSocialGridBox,
  ContactSocialGridContainer,
  ContactSocialGridIcon,
  ContactSocialGridSubTitle,
  ContactSocialGridTitle,
  ContactSubTitle,
  ContactTitle,
  SubmitButton,
} from "./contact.styled";
import * as Yup from "yup";
import React, { useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { RHFTextField } from "../../components/hook-form";
import axios, { endpoints, handleErrorResponse } from "../../utils/axios";
import { enqueueSnackbar } from "../../components/snackbar";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { SiLinkedin, SiSkype, SiTelegram } from "react-icons/si";

export function HomePageContactUsSection({ id }: { id: string }) {
  const FormSchema = Yup.object().shape({
    name: Yup.string().required("Full name is required"),
    email: Yup.string().required("Email address is required"),
    subject: Yup.string().required("Subject is required"),
    message: Yup.string().required("Message is required"),
  });

  const defaultValues = useMemo(
    () => ({
      name: "",
      email: "",
      subject: "",
      message: "",
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await axios.post(endpoints.contactUs.create, data);
      reset();
      enqueueSnackbar(
        "Message sent successfully! I'll come back to you soon.",
        { variant: "success" }
      );
    } catch (e) {
      handleErrorResponse(e, setError, enqueueSnackbar);
    }
  });

  return (
    <>
      <ContactSectionContainer id={id}>
        <ContactSectionWrapper>
          <ContactTitle>Contact Me</ContactTitle>

          <ContactSubTitle>
            Feel Free to Reach Me On Social Media or My Email Address
          </ContactSubTitle>

          <ContactSocialGridContainer>
            <ContactSocialGridBox>
              <ContactSocialGridIcon>
                <MdOutlineAlternateEmail size={50} />
              </ContactSocialGridIcon>
              <ContactSocialGridTitle>Email Address</ContactSocialGridTitle>
              <ContactSocialGridSubTitle>
                rezashams.work@gmail.com
              </ContactSocialGridSubTitle>
            </ContactSocialGridBox>

            <ContactSocialGridBox>
              <ContactSocialGridIcon>
                <SiLinkedin size={50} />
              </ContactSocialGridIcon>
              <ContactSocialGridTitle>Linkedin</ContactSocialGridTitle>
              <ContactSocialGridSubTitle>
                @reza.shams.1
              </ContactSocialGridSubTitle>
            </ContactSocialGridBox>

            <ContactSocialGridBox>
              <ContactSocialGridIcon>
                <SiTelegram size={50} />
              </ContactSocialGridIcon>
              <ContactSocialGridTitle>Telegram</ContactSocialGridTitle>
              <ContactSocialGridSubTitle>
                @rezashams96
              </ContactSocialGridSubTitle>
            </ContactSocialGridBox>
          </ContactSocialGridContainer>

          <ContactSubTitle>
            Or Fill the form below. I'll reach out as soon as possible.
          </ContactSubTitle>

          <FormProvider methods={methods} onSubmit={onSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <RHFTextField name="name" label="Full Name" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <RHFTextField name="email" label="Email Address" />
              </Grid>
              <Grid item xs={12} sm={12}>
                <RHFTextField name="subject" label="Subject" />
              </Grid>
              <Grid item xs={12} sm={12}>
                <RHFTextField
                  name="message"
                  label="Message Body"
                  multiline
                  rows={5}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <SubmitButton type="submit" isLoading={isSubmitting}>
                  Submit Form
                </SubmitButton>
              </Grid>
            </Grid>
          </FormProvider>
        </ContactSectionWrapper>
      </ContactSectionContainer>
    </>
  );
}
